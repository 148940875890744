import "src/style/theme.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/style/theme.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA3WWWW7bMBCG33sKAUaABKhTcri7T5Il3iOLszmxXWdzUvTunaEDlEOyEmzDH0aj2X6Si/12+9L9/tZ18/lzOKzXO7HoxM/sv8T/52D2q6ecAtESqkUnC6QRVXYGoRAnObKIdoecuPQkZ37RASdh0c0gXTm9QBoF3Tm9RDr0Q1yGnF4V+V4vOmd9TlYYCXhmdIPIaoZuMTZjGbqjMHS0ccjpPdJxGKdJ5fShGfK6GfIj0l7RndMnpMGH2E853SA1I9053SLVWveGJbqjYgaIitn+IipBA8tiv+j2t5cXp0p975T93mn8xTkRZ7nRc9NIG2b00jSy3NNr08hzo7emUeBG71TodOX0gHSKEbuS0w9K3fcyDoqV+pPaokPPfUiUzswPFgqMCppZNxpvGUYJzYTQ3hmGFb3TBa051tSxHqbAuiBRSjM1gvbc2lKSgNO0ZNjR4ECQRYAoq5kLVkYmIhnSK5UdOP4S1xg1w6SucRmXk2P4iko1RDfyReWaSmWn2PMAV1+l6tkEyps2RsnNpO2V7hkm2UnjBR9jeZ+srVE8bhKe1EYBt14fx8RPvLCPRyyXPO6nhCdfBLhJeOwdD3Cb8HLQvFQkv2h6kHxOSH9jcE5wvE9tMJPhAT7TKgBaCDbI8oVa7JURvPOvx3Rs5E7ejnHHkafznkTSx57P4CEtc8F47vsjOQk68Hp/NjGQdjAXq9krQTazBNIONljwNQlIO3qUo2T1Bt2UPJg2ts0tBVA7c9y1GPPF3gEh7WwMoVw2282KMdSK2B3SR1r8muOuRosBXZLX5eqfKejCFNiuANdp+TsVHd0/0PPJWXpO4ccefTD7VRn9TXurv23t9XBH1FX4vj4DwANt5bXfdetsAI9Ea7+oMCgZygtqB6guVTKUli4Z6qp6FkVlS4aK8iV7oT6X8JXOCSV8ozLJKqB3wqpO80Dc1rX6+A//bHZB0SHu3Ndc1t1R0OyOUq3uqHSeq+NQpm6PstSe2tS1uqZQSporR6GYbIFQTK5AqCVfINRMKBBqIxiOVvUpVNG5ThUMx18aYn/+Aus/j0EtCwAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/components/MavbotsArena/Arena/LatestTrades/BotCard/BotCard.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/MavbotsArena/Arena/LatestTrades/BotCard/BotCard.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA8VW246bMBB9z1dYK1VKpHUEuTXLvvQ3+miwAQewke2EZKv8e30B42zIXqRKlRIgZjg+M3POkGUnqk2+jsCfGQAtEbIlmaInkoA4iqL2/KqXO4pVaRd+mJ+YyrZGlwTkNbH3D0epaH6BGWeKMJWATB+JeJ1dZ0sHHzt4hDFlhUbaOeCUC0wEFAjTo0zAKhqWz1CWCPMuAXDTnt1hr786AIgiRfPVdvsMxkO0jHaLZ2DC7kIjfdt9lqvtIshnvR1ooKwqBD8yrFOouUjACYk5hPLlXFWtXNwkXQiKzYI5Q0UavaoIRIIgncFTLnQNnu7vC97p23Eu7C3UJiAyV0ogJqminCXuOuei0US3Emg84kPMMpTqUuu+tIJIIk4Eri2Plg8AguitdOuCuq9s3T2EjuFK0/k9j/cRJsUiCF3bUMvaJKO7a1IZypOjjMATlTSlNVW6DCXFmLAJdZSEFqUaf3+aomewsQzeIGWYnLUaXqep98SnuHqorYWa5jsWDKWS10flysyHntQkV/bSo+0+reKEKcwZYiqMncxmWlnHhk3aRbamuilRHXEU+YmIvOYd1HjoqHjA5aflYsG1ZLVB4vCBMM8vM/LY+w+xRzLeyVC4Xu+NjzzMi4WpqVROslBdWq1bxhkJnk3Azrj0C95yPKXb3XjI00A1LRikOlKOQ6f3V+9tRc4KdsKsMG7OAVFkiU5Yvl74eslSUFbd6iGdrNMEzmFaGpbf5qZm2SMqcRyaFD8KW92EEUdQ6wt2vR1TXuMgIv9wPwAaJArKoPPCOwkW9tnvNs0nfkObhrTLaeDBL9N9voMbR1BO1WCyYBf6gP4dWDBl7tMaZeiBD7fAnw+C+y3zUXheWR6/+lbdDcGHZc/7Jp9hP71X+/7d2zfejsN3+dXT+T18+z/057s3hh8IdhhkRyGNKFtOh/ieU8qV4nr6wr2javUt6Zv9r+KWJgRdLcKBZfOKVzfeq5PSzLhHjjiEEm3+Vw3+VbLNV5L91RBMEZCZ0C8kgBgG80Y3YOBsxbKwEMvw72NQmLTmWWW4XMeguA/qYYYhfp1d/wLK964NhQoAAA==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var avatarWrapper = 'wrk4f3h';
export var boldSymbol = 'wrk4f3e';
export var card = 'wrk4f31';
export var cardBack = 'wrk4f36';
export var cardFront = 'wrk4f34';
export var cardSide = 'wrk4f33';
export var cardWrapper = 'wrk4f30';
export var dateTime = 'wrk4f3a';
export var detailsGrid = 'wrk4f3k';
export var flipButton = 'wrk4f3m';
export var flippedCard = 'wrk4f32';
export var greenMessage = 'wrk4f3c';
export var headerWrapper = 'wrk4f3i';
export var hiddenBack = 'wrk4f35';
export var infoGrid = 'wrk4f3j';
export var listItem = 'wrk4f39';
export var mainCardGrid = 'wrk4f3g';
export var message = 'wrk4f3b';
export var redMessage = 'wrk4f3d';
export var showMoreButton = 'wrk4f3l';
export var size = 'wrk4f3f';
export var tradeHistoryContainer = 'wrk4f37';
export var tradeHistoryList = 'wrk4f38';