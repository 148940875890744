import React, { useEffect } from 'react'

import { Box } from 'components/layout/box'
import * as styles from './MavbotsArena.css'
import { MainTitle } from 'components/typography/main-title'
import { TabsProvider } from 'components/Tabs/TabsProvider'
import { Tabs } from 'components/Tabs'

import { MainInfoMavbotsArenaPageDialog } from 'components/Dialog/MainInfoMavbotsArenaPageDialog'
import { Arena } from './Arena'
import { Roster } from './Roster'
import { fetchBots, fetchBotsVideos, fetchQuote } from 'api/mavbots-arena'
import { useOktaAuth } from '@okta/okta-react'
import { useQuery } from '@tanstack/react-query'
import { useArenaContext } from 'context/ArenaContext'
import { fetchBotsImages } from 'api/mavbots-arena/fetchBotsImages'

export const MavbotsArena = () => {
	const { oktaAuth } = useOktaAuth()
	const accessToken = oktaAuth.getAccessToken()
	const { setArenaBotsData, setArenaSelectedBot, setArenaQuote, setAvatarImages, setAvatarVideos } = useArenaContext()

	const { data: arenaBotsData } = useQuery({
		queryKey: ['mavbots-arena', 'mavbots'],
		queryFn: () => fetchBots(accessToken)
	})

	const { data: botImages } = useQuery({
		queryKey: ['bot-images', arenaBotsData?.map(bot => bot.avatar)],
		queryFn: () =>
			fetchBotsImages(
				accessToken,
				arenaBotsData?.map(bot => bot.avatar)
			),
		enabled: !!arenaBotsData
	})

	const { data: botVideos } = useQuery({
		queryKey: ['bot-videos', arenaBotsData?.map(bot => bot.videoAvatar)],
		queryFn: () =>
			fetchBotsVideos(
				accessToken,
				arenaBotsData?.map(bot => bot.videoAvatar)
			),
		enabled: !!arenaBotsData
	})

	const { data: quoteTextData } = useQuery({
		queryKey: ['arena-quote'],
		queryFn: () => fetchQuote(accessToken)
	})

	useEffect(() => {
		if (arenaBotsData && arenaBotsData.length > 0) {
			setArenaSelectedBot(arenaBotsData[0])
			setArenaBotsData(arenaBotsData)
		}
	}, [arenaBotsData])

	useEffect(() => {
		if (quoteTextData) {
			setArenaQuote(quoteTextData.text)
		}
	}, [quoteTextData])

	useEffect(() => {
		if (botImages) {
			setAvatarImages(botImages)
		}
	}, [botImages, setAvatarImages])

	useEffect(() => {
		if (botVideos) {
			setAvatarVideos(botVideos)
		}
	}, [botVideos, setAvatarVideos])

	return (
		<Box className={styles.mavbotsArenaContainer}>
			<Box className={styles.header}>
				<MainTitle Dialog={children => <MainInfoMavbotsArenaPageDialog>{children}</MainInfoMavbotsArenaPageDialog>}>
					Mavbots Arena
				</MainTitle>
			</Box>
			<TabsProvider defaultTab="Arena" tabs={['Arena', 'Roster']}>
				<Tabs>
					<Tabs.Tab value="Arena">Arena</Tabs.Tab>
					<Tabs.Tab value="Roster">Roster</Tabs.Tab>

					<Tabs.Panel value="Arena">
						<Arena />
					</Tabs.Panel>
					<Tabs.Panel value="Roster">
						<Roster />
					</Tabs.Panel>
				</Tabs>
			</TabsProvider>
		</Box>
	)
}
