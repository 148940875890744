import React, { useEffect, useMemo } from 'react'

import { Box } from 'components/layout/box'
import * as styles from './Arena.css'

import { Card } from 'components/Card'
import { LatestTrades } from './LatestTrades/LatestTrades'
import { Leaderboard } from 'components/Leaderboard'
import { useTabsContext } from 'components/Tabs/TabsProvider'
import { useArenaContext } from 'context/ArenaContext'
import { FormProvider, useForm } from 'react-hook-form'
import { BotFilter } from '../components/BotFilters/BotFilter'
import { useOrientation } from '@uidotdev/usehooks'
import clsx from 'clsx'

interface FormValues {
	selectedStrategies: string[]
	selectedTickers: string[]
	selectedBotIds: number[]
}

export const Arena = () => {
	const { onActiveTabChange } = useTabsContext()
	const { arenaBotsData, setArenaSelectedBot } = useArenaContext()
	const { type: orientationType } = useOrientation()

	// Extract unique strategies, tickers, and bots
	const strategies = useMemo(() => {
		const strategySet = new Set<string>()
		arenaBotsData.forEach(bot => {
			if (bot.strategy) {
				strategySet.add(bot.strategy)
			}
		})
		return Array.from(strategySet)
	}, [arenaBotsData])

	const tickers = useMemo(() => {
		const tickerSet = new Set<string>()
		arenaBotsData.forEach(bot => {
			if (bot.trades && bot.trades.length > 0) {
				bot.trades.forEach(ticker => {
					tickerSet.add(ticker)
				})
			}
		})
		return Array.from(tickerSet)
	}, [arenaBotsData])

	const botOptions = useMemo(() => {
		return arenaBotsData.map(bot => ({
			label: bot.bot_name,
			value: bot.bot_id,
			avatar: bot.avatar
		}))
	}, [arenaBotsData])

	// Set up form methods
	const methods = useForm<FormValues>({
		defaultValues: {
			selectedStrategies: [],
			selectedTickers: [],
			selectedBotIds: []
		}
	})

	const { watch, reset } = methods
	const selectedStrategies = watch('selectedStrategies') || []
	const selectedTickers = watch('selectedTickers') || []
	const selectedBotIds = watch('selectedBotIds') || []

	useEffect(() => {
		if (strategies.length > 0 || tickers.length > 0 || botOptions.length > 0) {
			reset({
				selectedStrategies: strategies,
				selectedTickers: tickers,
				selectedBotIds: botOptions.map(option => option.value)
			})
		}
	}, [strategies, tickers, botOptions, reset])

	const filteredBots = useMemo(() => {
		return arenaBotsData.filter(bot => {
			const strategyMatch = selectedStrategies.includes(bot.strategy)
			const tickerMatch = bot.trades && bot.trades.some(ticker => selectedTickers.includes(ticker))
			const idMatch = selectedBotIds.includes(bot.bot_id)

			return strategyMatch && tickerMatch && idMatch
		})
	}, [arenaBotsData, selectedStrategies, selectedTickers, selectedBotIds])

	const handleAvatarClick = (botId: number) => {
		const arenaBot = arenaBotsData.find(bot => bot.bot_id === botId)
		if (!arenaBot) {
			return
		}
		setArenaSelectedBot(arenaBot)
		onActiveTabChange('Roster')
	}

	return (
		<Box className={styles.arenaContainer}>
			<Box
				className={clsx({
					[styles.portraitLayout]: orientationType === 'portrait-primary',
					[styles.layout]: orientationType !== 'portrait-primary'
				})}>
				<FormProvider {...methods}>
					<Card
						title="Latest Trades"
						headerChildren={
							<Box display="flex" gap={2}>
								<BotFilter<'selectedBotIds'> title="Bots" options={botOptions} field="selectedBotIds" />
								<BotFilter<'selectedStrategies'>
									title="Strategy"
									options={strategies.map(strategy => ({ label: strategy, value: strategy }))}
									field="selectedStrategies"
								/>
								<BotFilter<'selectedTickers'>
									title="Ticker"
									options={tickers.map(ticker => ({ label: ticker, value: ticker }))}
									field="selectedTickers"
								/>
							</Box>
						}
						style={{ gridArea: 'latestTrades' }}>
						<LatestTrades trades={filteredBots} />
					</Card>
				</FormProvider>
				<Card style={{ gridArea: 'leaderboard' }} title="Leaderboard">
					<Box>
						<Leaderboard isTransparent handleClick={handleAvatarClick} />
					</Box>
				</Card>
			</Box>
		</Box>
	)
}
