import * as styles from './BotFilter.css'

import React, { useState } from 'react'
import * as Popover from '@radix-ui/react-popover'
import { Button } from 'components/inputs/button'
import { Box } from 'components/layout/box'
import { Text } from 'components/typography/text'
import clsx from 'clsx'
import { useFormContext } from 'react-hook-form'

import { useArenaContext } from 'context/ArenaContext'
import { Search } from 'components/inputs/search'
import { tokens } from 'style/theme.css'
import { SearchIcon } from 'assets/icons/icons'

interface Option<T> {
	label: string
	value: T
	avatar?: string // Add avatar property
}

type FieldTypeMap = {
	selectedStrategies: string
	selectedTickers: string
	selectedBotIds: number
}

interface BotFilterProps<TField extends keyof FormValues> {
	options: Option<FieldTypeMap[TField]>[]
	title: string
	field: keyof FormValues
}

interface FormValues {
	selectedStrategies: string[]
	selectedTickers: string[]
	selectedBotIds: number[]
}

export function BotFilter<TField extends keyof FormValues>({ options, title, field }: BotFilterProps<TField>) {
	const { setValue, watch } = useFormContext<FormValues>()
	const [isOpen, setIsOpen] = useState(false)
	const [searchQuery, setSearchQuery] = useState('')

	type OptionValueType = FieldTypeMap[TField]

	const selectedOptions = (watch(field) || []) as OptionValueType[]
	const { avatarImages } = useArenaContext()

	// Toggle dropdown visibility
	const handleIsOpen = () => {
		setIsOpen(!isOpen)
	}

	// Handle checkbox change for each option
	const handleCheckboxChange = (optionValue: OptionValueType) => {
		const newValues = selectedOptions.includes(optionValue)
			? selectedOptions.filter(s => s !== optionValue)
			: [...selectedOptions, optionValue]

		setValue(field, newValues as FormValues[TField])
	}

	const handleClearAllChange = () => {
		if (selectedOptions.length === 0) {
			// Currently all options are unselected, so select all
			const allOptionValues = options.map(option => option.value)
			setValue(field, allOptionValues as FormValues[TField])
		} else {
			// Unselect all options
			setValue(field, [] as FormValues[TField])
		}
	}

	const isClearAllChecked = selectedOptions.length === 0

	// Filter options based on search query
	const filteredOptions = options.filter(option => option.label.toLowerCase().includes(searchQuery.toLowerCase()))

	return (
		<Popover.Root open={isOpen} onOpenChange={handleIsOpen}>
			<Popover.Trigger asChild>
				<div style={{ position: 'relative' }}>
					<Button variant="primary" size="small" type="button">
						{title}
					</Button>
				</div>
			</Popover.Trigger>
			<Popover.Portal>
				<Popover.Content className={clsx('PopoverContent', styles.content)} sideOffset={5}>
					<Box>
						<Search
							startIcon={<SearchIcon color={tokens.colors['neutral.500']} />}
							type="text"
							value={searchQuery}
							placeholder={`Search ${title.toLowerCase()}`}
							onChange={e => {
								setSearchQuery(e.target.value)
							}}
							onKeyDown={e => {
								if (e.key === 'Enter') {
									e.preventDefault()
								}
							}}
						/>

						<Box className={styles.dataListContainer}>
							<Box>
								<label className={styles.labelStyle}>
									<input
										type="checkbox"
										checked={isClearAllChecked}
										onChange={handleClearAllChange}
										className={styles.checkboxInput}
									/>
									<span className={styles.customCheckbox}></span>
									<Text variant="body2" color="primary.500">
										Clear
									</Text>
								</label>
							</Box>
							{filteredOptions.length > 0 ? (
								filteredOptions.map(option => {
									const imageSrc = option.avatar && avatarImages[option.avatar]
									return (
										<Box key={String(option.value)}>
											<label className={styles.labelStyle}>
												<input
													type="checkbox"
													checked={selectedOptions.includes(option.value)}
													onChange={() => handleCheckboxChange(option.value)}
													className={styles.checkboxInput}
												/>
												<span className={styles.customCheckbox}></span>
												{imageSrc && <img src={imageSrc} alt={option.label} className={styles.avatarImage} />}
												<Text variant="body2" color="neutral.50">
													{option.label}
												</Text>
											</label>
										</Box>
									)
								})
							) : (
								<Box className={styles.noResults}>
									<Text color="neutral.500">No results found</Text>
								</Box>
							)}
						</Box>
					</Box>
				</Popover.Content>
			</Popover.Portal>
		</Popover.Root>
	)
}
