import { PanelTopLeftIcon, PanelTopRightIcon } from 'assets/icons/icons'
import { Box } from 'components/layout/box'
import * as styles from './PanelTop.css'
import { useState, useEffect } from 'react'
import clsx from 'clsx'
import { Text } from 'components/typography/text'
import { TopReflection } from './TopReflection'
import { Calendar } from './Calendar'
import { TopBackgroundReflection } from './TopBackgroundReflection'
import { Leaderboard } from './Leaderboard'
import { ArenaProvider } from 'context/ArenaContext'

interface Props {
	expandedPanel: null | 'leaderboard' | 'calendar' | 'approach' | 'knowledgeBot'
	expanded: boolean
	onToggle: any
}

export const PanelTop = ({ expanded, onToggle, expandedPanel }: Props) => {
	const [transitionClass, setTransitionClass] = useState('')

	useEffect(() => {
		if (expanded) {
			setTransitionClass(styles.contentBoxClosing)
		} else {
			setTransitionClass(styles.contentBoxOpening)
		}
	}, [expanded])

	return (
		<Box className={styles.panelTop}>
			<Box className={clsx(styles.contentBox, expanded && styles.contentBoxExpanded, transitionClass)}>
				<Box className={styles.topReflection}>
					<TopReflection />
				</Box>
				<Box className={styles.topBackgroundReflection}>
					<TopBackgroundReflection />
				</Box>
				{expandedPanel === 'leaderboard' ? (
					<Box>
						<ArenaProvider>
							<Leaderboard />
						</ArenaProvider>
					</Box>
				) : (
					<Calendar />
				)}
			</Box>
			<Box style={{ pointerEvents: 'none' }}>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="144"
					height="83"
					viewBox="0 0 144 83"
					fill="none"
					style={{ zIndex: 2 }}>
					<path
						d="M34.0661 2.0084H2.0084C1.45148 2.0084 1 1.55693 1 1H143V82C142.448 82 142 81.5523 142 81V62.3548C142 51.6655 133.335 43 122.645 43H75.0577C56.8004 43 42 28.1995 42 9.94226C42 5.56051 38.4479 2.0084 34.0661 2.0084Z"
						fill="url(#paint0_radial_1607_1082)"
						fillOpacity="0.4"
						stroke="url(#border)"
						strokeWidth="2"
					/>
					<defs>
						<radialGradient
							id="paint0_radial_1607_1082"
							cx="0"
							cy="0"
							r="1"
							gradientUnits="userSpaceOnUse"
							gradientTransform="translate(1 5.05) rotate(0.532156) scale(142.207 63.7763)">
							<stop offset="0.0001" stopColor="#D9D9D9" />
							<stop offset="0.0002" stopColor="#D9D9D9" />
							<stop offset="1" stopColor="#D9D9D9" stopOpacity="0.01" />
						</radialGradient>
						<linearGradient id="border" x1="25.1685" y1="1" x2="100.988" y2="104.239" gradientUnits="userSpaceOnUse">
							<stop stopColor="#252525" />
							<stop offset="0.0334989" stopColor="#23D27E" />
							<stop offset="0.197086" stopColor="#23D27E" />
							<stop offset="0.241275" stopColor="#23D27E" />
							<stop offset="0.317125" stopColor="#B7DCDA" />
							<stop offset="0.45" stopColor="#131212" />
							<stop offset="0.532142" stopColor="#23D27E" stopOpacity="0.29" />
							<stop offset="0.72" stopColor="#147647" stopOpacity="0.605423" />
							<stop offset="0.955" stopColor="#020202" />
						</linearGradient>
						<linearGradient id="greenLight" x1="94" y1="0" x2="72" y2="43" gradientUnits="userSpaceOnUse">
							<stop offset="0.1" stopColor="transparent" />
							<stop offset="0.35" stopColor="#23D27E" stopOpacity="0.9" />
							<stop offset="0.65" stopColor="#23D27E" stopOpacity="0.9" />
							<stop offset="0.9" stopColor="transparent" />
						</linearGradient>
					</defs>
					<path d="M90 0V43" stroke="url(#greenLight)" strokeWidth="2" />
				</svg>
				<Box className={styles.rightIconWrapper} onClick={() => onToggle('leaderboard')}>
					<PanelTopRightIcon />
				</Box>
				<Box className={styles.leftIconWrapper} onClick={() => onToggle('calendar')}>
					<PanelTopLeftIcon />
				</Box>
			</Box>
		</Box>
	)
}
