import axios from 'axios'

export interface CumulativeReturns {
	time: string
	algo_perf: number
	benchmark_perf: number
}

export interface Badge {
	name: string
	icon: string
}

export interface TradeHistory {
	datetime: string
	size: string
	message: string
}

export interface Trader {
	bot_id: number
	bot_name: string
	description: string
	avatar: string
	videoAvatar: string
	riskLevel: 1 | 2 | 3
	badges: Badge[]
	strategy: string
	strategy_description: string
	trades: string[]
	open_positions: string[]
	perf_ytd: string
	perf_5y: string
	win_rate: string
	win_avg: string
	loss_rate: string
	loss_avg: string
	trade_history: TradeHistory[]
	bot_details: {
		cumulative_returns: CumulativeReturns[]
		summary: {
			perf_ytd: string
		}
		ten_year: {}
		risk_adjusted: {}
	}
}



const axiosClient = axios.create()

axiosClient.defaults.headers.common = {
	'Content-Type': 'application/json',
	Accept: 'application/json',
	'Access-Control-Allow-Origin': '*',
	'Access-Control-Allow-Credentials': true,
	'Access-Control-Allow-Headers': 'X-Requested-With,content-type'
}

// export type BotsResponse = {
// 	[key: string]: Trader
// }

// export const fetchBots = async (accessToken: string | undefined): Promise<BotsResponse> => {
// 	const response = await axiosClient.get(`${process.env.REACT_APP_API_SERVER}/storage/mavbots-arena`, {
// 		headers: {
// 			Authorization: `Bearer ${accessToken}`
// 		}
// 	})

// 	return response.data
// }

export const fetchBotsImages = async (
	accessToken: string | undefined,
	avatars?: string[]
): Promise<Record<string, string>> => {
	if (!avatars) {
		return {}
	}
	const imageFetches = avatars.map(async avatar => {
		const response = await fetch(`${process.env.REACT_APP_API_SERVER}/storage/fetch-image/${avatar}`, {
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		})

		if (response.ok) {
			const base64Data = await response.text()
			const base64Image = `data:image/jpeg;base64,${base64Data}`
			return { [avatar]: base64Image } // Return the image with the bot's file name as the key
		} else {
			console.error('Error fetching image:', response.statusText)
			return { [avatar]: '' } // Return an empty image in case of failure
		}
	})

	// Wait for all image fetches to complete and merge them into one object
	const imageDataArray = await Promise.all(imageFetches)
	return Object.assign({}, ...imageDataArray) // Merge all image objects into one
}
