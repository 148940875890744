if (process.env.NODE_ENV === 'production') {
	console.log = () => {}
	console.warn = () => {}
	console.error = () => {}
	console.trace = () => {}
	console.debug = () => {}
}

console.log('process.env', process.env)

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID || ''
const ISSUER = process.env.REACT_APP_ISSUER || ''
const OKTA_TESTING_DISABLEHTTPSCHECK = process.env.REACT_APP_OKTA_TESTING_DISABLEHTTPSCHECK || false
const BASENAME = process.env.REACT_APP_PUBLIC_URL || ''
const REDIRECT_URI = `${window.location.origin}${BASENAME}/login/callback`
const USE_INTERACTION_CODE = process.env.REACT_APP_USE_INTERACTION_CODE === 'true' || false
const GOOGLE_IDP_ID = process.env.REACT_APP_GOOGLE_IDP_ID || ''
const APPLE_IDP_ID = process.env.REACT_APP_APPLE_IDP_ID || ''

const oktaAuthConfig = {
	oidc: {
		clientId: CLIENT_ID,
		issuer: ISSUER,
		redirectUri: REDIRECT_URI,
		scopes: ['openid', 'profile', 'email', 'offline_access'],
		pkce: true,
		disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
		useInteractionCode: USE_INTERACTION_CODE,
		responseType: 'SPA',
		tokenManager: {
			autoRenew: true
		}
	},
	app: {
		basename: BASENAME
	}
}

export { oktaAuthConfig }
