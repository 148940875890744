import React, { useRef, useState, useEffect } from 'react'
import { Box } from 'components/layout/box'
import * as styles from './LatestTrades.css'
import { BotCard } from './BotCard/BotCard'
import { Trader } from 'api/mavbots-arena/fetchBots'
import { Text } from 'components/typography/text'
import { useOrientation, useWindowSize } from '@uidotdev/usehooks'
import { breakpoints } from 'style/tokens/breakpoints'
import { IconButton } from 'components/inputs/icon-button'
import { BlockIcon } from 'components/icons/block-icon'
import { SmallLeftArrow, SmallRightArrow } from 'assets/icons/icons'
import clsx from 'clsx'

interface LatestTradesProps {
	trades: Trader[]
}

export const LatestTrades: React.FC<LatestTradesProps> = ({ trades }) => {
	const [currentCard, setCurrentCard] = useState(1) // Track the current card index
	const size = useWindowSize()
	const isMobile = size && size.width && size.width < Number(breakpoints.tablet)
	const { type: orientationType } = useOrientation()

	const scrollContainerRef = useRef<HTMLDivElement>(null)
	const cardRefs = useRef<Array<HTMLDivElement | null>>([])

	useEffect(() => {
		if (!isMobile) return // Only apply on mobile

		const options = {
			root: scrollContainerRef.current,
			rootMargin: '0px',
			threshold: 0.5 // 50% of the card is visible
		}

		const callback = (entries: IntersectionObserverEntry[]) => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					const index = Number(entry.target.getAttribute('data-index'))
					setCurrentCard(index + 1) // Assuming index starts from 0
				}
			})
		}

		const observer = new IntersectionObserver(callback, options)

		cardRefs.current.forEach(card => {
			if (card) {
				observer.observe(card)
			}
		})

		return () => {
			observer.disconnect()
		}
	}, [isMobile, trades])

	// Reset scroll position and currentCard when trades change
	useEffect(() => {
		if (scrollContainerRef.current) {
			scrollContainerRef.current.scrollTo({ left: 0, behavior: 'smooth' })
			setCurrentCard(1)
		}
	}, [trades])

	// Handle card navigation
	const handleNext = () => {
		if (scrollContainerRef.current) {
			const nextIndex = Math.min(currentCard, trades.length - 1)
			const nextCard = cardRefs.current[nextIndex]
			if (nextCard) {
				nextCard.scrollIntoView({ behavior: 'smooth', inline: 'start' })
			}
		}
	}

	const handlePrev = () => {
		if (scrollContainerRef.current) {
			const prevIndex = Math.max(currentCard - 2, 0)
			const prevCard = cardRefs.current[prevIndex]
			if (prevCard) {
				prevCard.scrollIntoView({ behavior: 'smooth', inline: 'start' })
			}
		}
	}

	return (
		<Box className={styles.latestTradesLayout}>
			{trades && trades.length > 0 ? (
				<>
					{isMobile ? (
						// Mobile view: Show one card at a time with navigation buttons
						<>
							<div className={styles.scrollWrapper} ref={scrollContainerRef}>
								{trades.map((bot, index) => (
									<div
										key={bot.bot_id}
										className={styles.botCardWrapper}
										ref={el => (cardRefs.current[index] = el)}
										data-index={index}>
										<BotCard data={bot} />
									</div>
								))}
							</div>

							{/* Single card indicator below the cards */}
							<Box className={styles.cardIndicator}>
								<Text variant="body2" color="neutral.300" textAlign="center">
									{currentCard}/{trades.length}
								</Text>
							</Box>

							<Box className={styles.navigationButtons}>
								<IconButton variant="action" onClick={handlePrev} className={styles.button}>
									<BlockIcon icon={SmallLeftArrow} />
								</IconButton>

								<IconButton variant="action" onClick={handleNext} className={styles.button}>
									<BlockIcon icon={SmallRightArrow} />
								</IconButton>
							</Box>
						</>
					) : (
						// Desktop view: Show all cards in a grid
						<Box
							className={clsx({
								[styles.botCardPortraitGrid]: orientationType === 'portrait-primary',
								[styles.botCardGrid]: orientationType !== 'portrait-primary'
							})}>
							{trades.map(bot => (
								<BotCard key={bot.bot_id} data={bot} />
							))}
						</Box>
					)}
				</>
			) : (
				<Text variant="body1" color="neutral.500" textAlign="center">
					No Results
				</Text>
			)}
		</Box>
	)
}
